<template>
  <div class="container"> 
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">租赁设备</div>
      <div @click="href()" class="header-left">
        <img class="order-icon" src="@/accesst/rent/order-icon.png" alt="" srcset="" />
        我的订单
      </div>
    </div>
    <div class="h85"></div>
    <img class="device-img" src="@/accesst/rent/device-icon.png" alt="" srcset="" />
    <div class="sub-title">租赁时长</div>
    <div class="rent-list">
      <div class="rent-list-item" @click="activeIndex = index" :class="{'active': activeIndex === index}" v-for="(item, index) in list" :key="index">
        <div class="rent-list-item-line1" :class="{'active': activeIndex === index}">
          <div class="t1">{{ item.leaseMonthsName }}</div>
          <div class="t2"><span>&yen;</span>{{item.monthlyRent * item.leaseMonths}}</div>
        </div>
        <div class="rent-list-item-tip" :class="{'active': activeIndex === index}">{{ item.leaseDaysGiveName }}</div>
        <div v-show="item.status == 1" class="rent-item-hot">火热</div>
      </div>      
    </div>
    <div class="sub-title">邮寄信息
      <!-- <div class="sub-title-tip">通讯录</div> -->
    </div>
    <div class="user-info">
      <div class="user-info-line-1">
        <div class="user-info-line-1-1">
          <div class="u-label">姓名</div>
          <van-field v-model="form.consignee" label-width="0px" maxlength="11" placeholder="请输入您的姓名"/>
        </div>
        <div class="user-info-line-1-1">
          <div class="u-label">电话</div>
          <van-field v-model="form.phone" label-width="0px" maxlength="11" placeholder="请输入您的电话"/>
        </div>
      </div>
      <div class="user-info-line-2">
        <div class="u-label">详细地址</div>
        <van-field v-model="form.deliveryAddr" label-width="0px" maxlength="100" placeholder="请输入您的收货地址"/>        
      </div>
    </div>
    <div v-if="list && list.length" class="rent-info">
      <div>每月租金：¥{{list[activeIndex].monthlyRent}}/月</div>
      <div>设备押金(可退还)：¥{{list[activeIndex].deivceDeposit}}</div>
    </div>

    <div class="rent-bottom">
      <div class="rent-b-left">
        <div class="li-1">总费用&nbsp;
          <span class="FC6106">&yen;</span>
          <span v-if="list && list.length" class="FC6106 font63">{{ list[activeIndex].leaseTotalMoney }}</span></div>
        <div class="li-2">
          <img v-if="checked" src="@/accesst/rent/checked.png" alt="" />
          <img v-else src="@/accesst/rent/check.png" alt="" />
          <span @click="checked= !checked">已了解并同意&nbsp;&nbsp;</span>
          <a @click="show=true" style="color:#0074FE">眼保仪设备租赁协议</a>
        </div>
      </div>
      <div @click="addOrder" v-preventReClick class="rent-b-right">确认并支付</div>
    </div>
    <!-- <div class="h190"></div> -->
    <van-popup
      v-model="show"
      closeable
      position="bottom"
      :style="{ height: '75%' }"
    >
    <iframe src="/xieyi.html" style="width: 100%;height: 100%;overflow: auto;" frameborder="0"></iframe>
    </van-popup>
  </div>  
</template>

<script>
import { Icon, RadioGroup, Radio, DatetimePicker, Popup, Picker,Field, NumberKeyboard, CellGroup,Button } from 'vant';
import { rentConfig, orderNew } from '@/api/rent.js'
import { wxJsApi, wxJsPay } from '@/api/h5.js'
import { findIndex } from 'lodash';
export default {
  components: {[Icon.name]: Icon, [Field.name]: Field, [Popup.name]: Popup},
  data() {
    return {
      show: false,
      list: [],
      activeIndex: 1,
      value1: '',
      checked: true,
      form: {
        phone: '',
        consignee: '',
        deliveryAddr: ''
      },
      pro: {},
    }
  },
  created() {
    rentConfig({}).then(res => {
      console.log(res, 'rentConfig')
      if(res) {
        this.pro = res;
        this.list = res.rentConfig;
        this.activeIndex = findIndex(res.rentConfig, {isDefault: 1})
      }
    })
  },
  methods: {
    // 生成订单
    addOrder() {
      if(!this.checked) {
        this.$toast('请同意《眼保仪设备租赁协议》');
        return false
      }

      if(!this.form.consignee) {
        this.$toast('请填写收货人');
        return false
      }

      if(!this.form.deliveryAddr) {
        this.$toast('请填写收货详细地址');
        return false
      }
      
      var regExp = /^1[3456789]\d{9}$/;
      if(!regExp.test(this.form.phone)) {
        this.$toast('请填写正确的手机号');
        return false
      }
      const rentConfigId = this.list[this.activeIndex].id;
      // TODO  对接微信支付 更新 paymentOrderId
      wxJsApi({ url: window.location.href }).then(msg1 => {
        console.log(msg1, '------> jssdk api')
        wxJsPay({ 
          price: this.list[this.activeIndex].leaseTotalMoney * 100,
          channelCode: 'wx_pub',
          channelExtras: {
            openid: sessionStorage.getItem('openid')
          },
        }).then(msg => {
          console.log(msg, '------> jssdk pay')
          const displayContent = JSON.parse(msg.displayContent);
          console.log(displayContent, '------> displayContent')
          this.$wxpay(msg1, displayContent, (res) => {
            // 生成订单
            console.log('pay res----->', res)
            if(res === 'success') {
              orderNew({...this.form, rentConfigId,leaseProdId:this.pro.id, paymentOrderId: msg.paymentOrderId}).then(() => {
                this.$toast('成功');
                this.$router.push({path: '/rent/list'})
              }).catch(e => {
                this.$toast(e.msg);
              })
            }
          })
        })
      })
      
    },
    href() {
      this.$router.push({ path: '/rent/list' })
    }
  }
}
</script>


<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background: #FFF;
  overflow: auto;
  .rent-b-left {
    padding-left: .38rem;    
    .li-1 {
      font-weight: 400;
      font-size: .27rem;
      color: #000000;
      .FC6106 {
        color: #FC6106
      }
      .font63 {
        font-size: .63rem;
      }
    }
    .li-2 {
      display: flex;
      align-items: center;
      img {
        width: .3rem;
        height: .3rem;
        // zoom: 1.2
      }
      padding-top: .12rem;
      font-weight: 400;
      font-size: .23rem;
      color: #999999;
    }
    
  }
  .rent-bottom {
    width: 7.50rem;
    height: 1.65rem;
    background: #FFFFFF;
    box-shadow: 0px -.08rem .19rem -.1rem rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rent-b-right {
      width: 2.19rem;
      height: .8rem;
      background: linear-gradient( 90deg, #FFCBA3 0%, #F8A1A4 100%);
      box-shadow: 0px .04rem .15rem -.08rem rgba(154,57,57,0.3);
      border-radius: .44rem;
      text-align: center;
      line-height: .8rem;
      font-weight: 600;
      font-size: .27rem;
      color: #5B2000;
      margin-right: .38rem;
    }
  }
  .h190 {
    height: 1.9rem
  }
  .rent-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .3rem auto;
    width: 6.43rem;
    height: .79rem;
    padding: 0 .15rem;
    background: rgba(0,116,254,0.08);
    border-radius: .08rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: .27rem;
    color: #0074FE;
  }
  .user-info {
    padding: 0 .38rem 0;
    font-size: .27rem;
    .u-label {
      color: #999;
      display: inline-block;
      min-width: fit-content;
    }
    .user-info-line-1 {
      display: flex;
      gap: .54rem;
      height: .96rem;
      align-items: center;
      .user-info-line-1-1 {
        display: flex;
        height: .96rem;
        align-items: center;
        border-bottom: .02rem solid #EEEEEE; 
      }
    }
    .user-info-line-2 {
      display: flex;
      gap: .0rem;
      height: .96rem;
      align-items: center;
      border-bottom: .02rem solid #EEEEEE; 
    }
  }
  .sub-title {
    padding: .25rem 0 .3rem .38rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: .31rem;
    color: #000000;    
    font-style: normal;
    .sub-title-tip {
      display: inline-block;
      margin-left: .3rem;
      width: .96rem;
      height: .42rem;
      border-radius: .21rem;
      border: .02rem solid #EEEEEE;
      text-align: center;
      font-weight: 400;
      font-size: .23rem;
      color: #000000;
      line-height: .42rem;
    }
  }
  .device-img {
    width: 7.5rem;
    height: 4.35rem;
  }
  .rent-list {
    padding: 0 0 0 0.38rem;
    gap: .19rem;
    display: flex;
    flex-wrap: wrap;
    .rent-list-item {
      position: relative;
      width: 2.12rem;
      height: 2.27rem;
      background: #EEEEEE;
      border-radius: .29rem;
      .rent-item-hot {
        position: absolute;
        top: -0.15rem;
        left: 0;
        width: .96rem;
        height: .46rem;
        background: url('../../accesst/rent/hot.png') no-repeat;
        background-size: .96rem .46rem;
        z-index: 1;
        font-weight: 400;
        font-size: .23rem;
        color: #FFFFFF;
        line-height: .46rem;
        text-align: center;
      }      
      .rent-list-item-tip {
        padding-top: .12rem;
        font-weight: 400;
        font-size: .23rem;
        color: #2B67D9;
        text-align: center;
      }
      .rent-list-item-tip.active {
        color: #FFF;
      }
      .rent-list-item-tip-un.active {
        color: #FFF;
      }
      .rent-list-item-tip-un {
        text-align: center;
        padding-top: .12rem;
        font-weight: 400;
        font-size: .23rem;
        color: #868686;
      }
      .rent-list-item-line1.active {
        height: 1.52rem;
        background: #E9F3F5;
        border-radius: .27rem;
      }
      .rent-list-item-line1 {
        margin: .04rem;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #000000;
        .t1 {          
          font-size: .27rem;          
          padding-top: .3rem;          
        }
        .t2 {
          font-size: .46rem;
          span {
            font-size: .27rem;
          }
        }        
      }
    }
    .rent-list-item.active {
      background: #2B67D9;
    }    
  }
  .h85 {
    height: .85rem;
  }
  
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      display: flex;
      align-items: center;
      width: auto;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 400;
      font-size: .27rem ;
      color: #2B67D9;
      .order-icon {
        width: .32rem;
        height: .32rem;
        margin-right: .08rem;
      }
    }
  }
}
</style>  